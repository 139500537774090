.cmt-drawer {
  flex-shrink: 0;
  position: absolute;
  left: -100%;
  transition: all 0.2s ease;
  z-index: 999;

  &--visible {
    left: 0;
  }

  & .paper {
    width: 375px;
    height: 100vh;
    position: initial;

    @media (min-width: 1024px) {
      position: fixed;
      width: 240px;
    }
  }

  & .close {
    position: absolute;
    top: 5px;
    left: 5px;

    @media (min-width: 1024px) {
      display: none;
    }
  }

  & .list {
    margin-top: 50px;
    @media (min-width: 1024px) {
      margin-top: 0;
    }
  }
}
