.login-page {
  .full-page {
    height: 100vh;
  }

  .auth-card {
    width: 340px;
    max-width: 100%;
  }

  .buttons-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
  }
}



