@import './variables';

@for $i from 0 through 20 {
  // padding
  .p-#{$i} {
    padding: $spacingUnit * $i !important;
  }
  .pt-#{$i} {
    padding-top: $spacingUnit * $i !important;
  }
  .pb-#{$i} {
    padding-bottom: $spacingUnit * $i !important;
  }
  .pl-#{$i} {
    padding-left: $spacingUnit * $i !important;
  }
  .pr-#{$i} {
    padding-right: $spacingUnit * $i !important;
  }
  .ph-#{$i} {
    padding-left: $spacingUnit * $i !important;
    padding-right: $spacingUnit * $i !important;
  }
  .pv-#{$i} {
    padding-bottom: $spacingUnit * $i !important;
    padding-top: $spacingUnit * $i !important;
  }
  // margin
  .m-#{$i} {
    margin: $spacingUnit * $i !important;
  }
  .mt-#{$i} {
    margin-top: $spacingUnit * $i !important;
  }
  .mb-#{$i} {
    margin-bottom: $spacingUnit * $i !important;
  }
  .ml-#{$i} {
    margin-left: $spacingUnit * $i !important;
  }
  .mr-#{$i} {
    margin-right: $spacingUnit * $i !important;
  }
  .mh-#{$i} {
    margin-left: $spacingUnit * $i !important;
    margin-right: $spacingUnit * $i !important;
  }
  .mv-#{$i} {
    margin-bottom: $spacingUnit * $i !important;
    margin-top: $spacingUnit * $i !important;
  }
}

.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}

.bg-light {
  background-color: $lightGray;
}

.d {
  &-block {
    display: block;
  }
  &-inline-block {
    display: inline-block;
  }
  &-flex {
    display: flex;
  }
  &-inline-flex {
    display: inline-flex;
  }
}

.responsive-image {
  max-width: 100%;
}
.text--muted {
  color: #868686;
}

.MuiContainer-root {
  max-width: 100vw;
}
