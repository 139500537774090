@import '../../scss/variables';

.default-layout {
  display: flex;

  &--content {
    flex-grow: 1;
    padding-top: 70px;

    @media (min-width: 1024px) {
      width: calc(100% - 240px);
      margin-left: 240px;
      padding-top: 70px;
    }
  }
  .success {
    background-color: $success;
  }
  .error {
    background-color: $error;
  }

  .active-menu-item {
    background: rgba($color: #000000, $alpha: 0.15);
  }
}
