//colors
$lightGray: #efefef;
$lightBlue: #e1f5fe;
$lightBlue2: #81d4fa;
$lightGray2: #838383;
$gray: #424141;
$success: #4caf50;
$error: #f44336;
//units
$spacingUnit: 0.25rem;
