.ConnectDefault__contract-body {
  font-family: 'Times New Roman, Arial';
  font-size: 11px;
  padding: 0;
  margin: 0;

  * {
    margin: 0;
    padding: 0;
  }
}

.ConnectDefault__h1 {
  font-size: 12px;
  float: left;
  margin-left: 150px;
}

.ConnectDefault__box {
  width: 100%;
  height: 100%;
  padding: 10px;
  margin: 10px 0;
}

.ConnectDefault__box-border {
  width: 90%;
  height: 100%;
  padding: 10px 20px 10px 10px;
  margin: 10px 0;
  border: thin solid #000;
}

.ConnectDefault__title-right {
  text-align: right;
  line-height: 200%;
  margin: 0 0 5px 0;
}

.ConnectDefault__underlineSolid {
  border-bottom: thin solid #000;
  min-width: 200px;
  height: 16px;
  display: inline-block;
  text-align: center;
}

.ConnectDefault__underlineDotted {
  border-bottom: thin dotted #000;
  min-width: 200px;
  height: 16px;
  display: inline-block;
  text-align: center;
  margin: 0 5px;
}

.ConnectDefault__checkbox {
  position: relative;
  width: 20px;
  height: 20px;
  margin: 0 5px;
  border: thin solid #000;
  display: inline-grid;
  font-family: 'Times New Roman, Arial';
  font-size: 11px;
  text-align: center;
  vertical-align: middle;
}

.ConnectDefault__checkbox.cross:after {
  content: 'X';
}

.ConnectDefault__contract-body {
  padding: 20px;
  max-width: 768px;
  margin: 0 auto;
}
.ConnectDefault__contract-body p {
  line-height: 20px;
}

@media print {
  @page {
    size: auto !important;
    margin: 10px 20px !important;
  }

  .ConnectDefault__border {
    padding: 12px !important;
  }

  .ConnectDefault__contract-body {
    margin: 0px !important;
    padding: 20px 0 0 0 !important;
  }
}
