@import './scss/variables';
@import './scss/resets';
body {
  background: $lightGray;
}

.fab-button {
  position: fixed !important;
  bottom: 5rem;
  right: 5rem;
}
