.body {
  font-size: 12px;
  padding: 0;
  margin: 0;
  border: none;
  font-family: Arial, sans-serif;
  text-align: right;
  color: #000;
  background-color: #fff;
}
.text-center {
  text-align: center;
}
.underline {
  text-decoration: underline;
}
.underlineDotted {
  border-bottom: 2px dotted #000;
  min-width: 200px;
  height: 16px;
  display: inline-block;
  text-align: center;
}
.underlineSolid {
  border-bottom: thin solid #000;
  min-width: 200px;
  height: 16px;
  display: inline-block;
  text-align: center;
}
.width50 {
  min-width: 50px;
}
ol li,
p,
h1 {
  margin-bottom: 20px;
}

ol.dots {
  list-style: disc;
  margin: 0 20px 0 0;
  padding: 0;
}

.square {
  width: 16px;
  height: 16px;
  border: solid 1px black;
  display: inline-block;
  position: relative;
  margin: 0 5px;
  vertical-align: middle;
}

.square.cross:after {
  content: 'X';
  position: absolute;
  left: 3px;
  top: -1px;
}

.border {
  border: solid 1px black;
  margin-bottom: 20px;
  padding: 15px;
}
.signatures li {
  list-style-type: none;
  width: 50%;
  float: right;
  margin-bottom: 1em;
  font-weight: bold;
  font-size: 20px;
}
.contract-body {
  padding: 20px;
}
.contract-body p,
.contract-body .terms-list li {
  margin-bottom: 5px;
}
.contract-body .terms-list-compact li {
  margin-bottom: 5px;
  font-size: 12px;
}
.contract-body p {
  line-height: 20px;
}
@media print {
  @page {
    size: auto !important;
    margin: 10px 20px !important;
  }

  .page-break {
    page-break-before: always !important;
  }

  .terms-list-compact li,
  .contract-body p {
    font-size: 10px !important;
    margin-bottom: 3px !important;
    line-height: 15px !important;
  }
  .border {
    padding: 12px !important;
  }
  body,
  .body,
  .contract-body {
    margin: 0px !important;
    padding: 0px !important;
  }
}
