@import "../../scss/variables";
 
.auth-layout {
  background: #474747;
  .success {
    background-color: $success;
  }
  .error {
    background-color: $error;
  }
}
