.cmt-app-bar {
  z-index: 10 !important;
  box-shadow: none!important;
  position: absolute!important;

  @media (min-width: 768px) {
    position: fixed!important;
  }

  & .MuiToolbar-root {
    padding-left: 0;
    padding-right: 0;
    min-height: 50px;

    @media (min-width: 1024px) {
      padding-left: 24px;
      padding-right: 24px;
      min-height: 56px;
      width: calc(100% - 240px);
      margin-left: 240px;
    }
  }


  &-hamburger {
    color: black!important;

    @media (min-width: 1024px) {
      display: none!important;
    }
  }

  &-content {
    width: 100%;

    @media (min-width: 1024px) {
      font-size: 16px;
    }
  }

  &-logout {
    font-size: 12px;

    @media (min-width: 1024px) {
      font-size: 14px;
    }
  }
}
